import React from 'react';
import { QuestionStatus, Referee } from '../Types/referee';
import { ArrowRightCircleIcon, CheckCircleIcon, MinusCircleIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

interface IMessageBubbleProps {
    referee: Referee;
}

const QuestionsModal: React.FC<IMessageBubbleProps> = ({ referee }) => {
    const navigate = useNavigate();

    const answeredCount = referee.questions.filter(q => q.status === QuestionStatus.Answered || q.status === QuestionStatus.Skipped).length;

    const handleDecline = () => {
        navigate(`/conversation/${referee.id}/abandon`);
    };

    return (
        <>
            <p className="text-center text-xs font-medium">You have completed {answeredCount} of {referee.questions.length} questions.</p>
            <ul className="space-y-3 font-medium py-4">
                {referee.questions.map((q, index) => {
                    const { backgroundColor, textColor, icon } = getStatusStyle(q.status);
                    return (
                        <li key={index} className={`${backgroundColor} rounded px-3 py-3 flex`}>
                            {!q.status ? `${index + 1}.` : icon} <span className={textColor}>{q.question}</span>
                        </li>
                    );
                })}
            </ul>
            <div className="flex justify-center pb-16">
                <button onClick={handleDecline} className="bg-red-100 hover:bg-red-200 text-red-400 text-sm py-1 px-2 rounded">
                    Decline Reference Check
                </button>
            </div>
        </>
    );
};

export default QuestionsModal;

const getStatusStyle = (status: QuestionStatus) => {
    switch (status) {
        case QuestionStatus.Answered:
        case QuestionStatus.Skipped:
            return {
                backgroundColor: 'bg-emerald-50',
                textColor: 'text-emerald-500',
                icon: <CheckCircleIcon className="h-5 w-5 mt-0.5 mr-2 text-emerald-500" />
            };
        case QuestionStatus.Current:
            return {
                backgroundColor: 'bg-blue-50',
                textColor: 'text-blue-500',
                icon: <ArrowRightCircleIcon className="h-5 w-5 mt-0.5 mr-2 text-blue-500" />
            };
        case QuestionStatus.Pending:
        default:
            return {
                backgroundColor: 'bg-gray-50',
                textColor: 'text-gray-500',
                icon: <MinusCircleIcon className="h-5 w-5 mt-0.5 mr-2 text-gray-500" />
            };
    }
};
