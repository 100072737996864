import React from 'react';
import { Referee } from '../Types/referee';

interface ICancelledProps {
    referee: Referee;
}

const Cancelled: React.FC<ICancelledProps> = ({ referee }) => {
    return (
        <div className="px-3 lg:px-5 flex flex-col h-screen max-w-6xl space-y-12 lg:space-y-20 m-6 md:m-16 lg:mx-auto">
            <div>
                <img className="h-12 lg:h-20" src={referee.orgLogoUrl} alt="Uniting" />
            </div>
            <h1 className="text-3xl lg:text-4xl text-left font-extrabold">This reference check is no longer available.</h1>
            <div>
                <p className="text-base text-left max-w-2xl">
                    If you think this is wrong, you can reach out to the candidate.
                </p>
            </div>
        </div>
    );
};

export default Cancelled;
