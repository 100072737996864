import { Referee } from '../Types/referee';

// const BASE_URL =
//     window.location.hostname === 'localhost'
//         ? "http://localhost:7071/api"
//         : "https://zipline-refcheck-prod.azurewebsites.net/api";

const BASE_URL = "https://zipline-refcheck-prod.azurewebsites.net/api";

export default class apiService {
    static async getConversation(guid: string | undefined): Promise<Referee | null> {
        const response = await fetch(`${BASE_URL}/referee/${guid}`);
        if (response.status === 404)
            return null;

        const data = await response.json() as Referee;
        return data;
    };


    static async postMessage(guid: string | undefined, message: string): Promise<Referee> {
        const response = await fetch(`${BASE_URL}/referee/${guid}/chat`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ answer: message }),
        });
        const data = await response.json();
        return data;
    }

    static async acknowledgeWelcomeMessage(guid: string | undefined): Promise<Referee> {
        const response = await fetch(`${BASE_URL}/referee/${guid}/acknowledge`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        return data;
    }

    static async abandonReferenceCheck(guid: string | undefined): Promise<Referee> {
        const response = await fetch(`${BASE_URL}/referee/${guid}/abandon`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status: 'abandoned' })
        });
        const data = await response.json();
        return data;
    }
}
