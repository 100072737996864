import React, { useEffect, useState } from 'react';
import { Referee } from '../Types/referee';
import { useNavigate, useParams } from 'react-router-dom';
import apiService from '../Services/apiService';
import LoadingScreen from './Loading';
import { Link } from 'react-router-dom';


const Abandon = () => {
    let { guid } = useParams<{ guid: string }>();
    const navigate = useNavigate();
    const [referee, setReferee] = useState<Referee | null>(null);
    const [isNotFound, setIsNotFound] = useState(false);
    const [isAbandoning, setIsAbandoning] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchConversation = async () => {
            setIsLoading(true);
            try {
                const response = await apiService.getConversation(guid);
                if (response === null) {
                    setIsNotFound(true);
                    setIsLoading(false);
                    return;
                }

                setReferee(response);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching conversation:', error);
            }
        };

        fetchConversation();
    }, [guid]);

    const handleAbandonReferenceCheck = async () => {
        if (guid) {
            try {
                setIsAbandoning(true);
                await apiService.abandonReferenceCheck(guid);
                navigate(`/conversation/${guid}`);
            } catch (error) {
                setIsAbandoning(false);
                console.error("Error abandoning", error);
            }
        }
    };

    if (isLoading) {
        return <LoadingScreen />
    }

    if (isNotFound || !referee) {
        return <p>Reference Check not found...</p>;
    }

    return (
        <div className="px-3 lg:px-5 flex flex-col h-screen max-w-6xl space-y-12 lg:space-y-20 m-6 md:m-16 lg:mx-auto">
            <div>
                <img className="h-12 lg:h-20" src={referee.orgLogoUrl} alt="Uniting" />
            </div>
            <h1 className="text-3xl lg:text-4xl text-left font-extrabold">Are you sure you'd like to decline the reference check for <span className='font-extrabold bg-blue-50 rounded px-2 py-1'>{referee.candidateName}</span>?</h1>
            <div className='space-y-8'>
                <p className="text-base text-left max-w-2xl">
                    <span className='font-bold'>{referee.candidateName}</span> has nominated you as one of their referees, and your feedback would be highly valuable in helping them progress through the interview process.</p>
                <p>If you came here by mistake, you can <Link className='font-bold text-blue-500' to={`/conversation/${referee.id}`}>return to the Reference Check</Link>.</p>
                <p>If you would like to decline completing the Reference Check, please do so below.</p>
                <div className='flex justify-center'>
                    <button
                        type="button"
                        className='rounded p-3 bg-red-400 hover:bg-red-600 text-white'
                        disabled={isAbandoning}
                        onClick={handleAbandonReferenceCheck}>
                        Decline
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Abandon;
