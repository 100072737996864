import { ReferenceCheckStatus } from '../Types/referee';

export function isCompleted(status: ReferenceCheckStatus) {
    return [ReferenceCheckStatus.Completed].includes(status);
}

export function isCancelled(status: ReferenceCheckStatus) {
    return [ReferenceCheckStatus.Cancelled].includes(status);
}

export function isAbandoned(status: ReferenceCheckStatus) {
    return [ReferenceCheckStatus.Abandoned].includes(status);
}

export function isActive(status: ReferenceCheckStatus) {
    return [ReferenceCheckStatus.Pending, ReferenceCheckStatus.InProgress].includes(status);
}