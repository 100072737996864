// Conversation.tsx
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid'
import MessageBubble from './MessageBubble';
import Header from './Header';
import TypingAnimation from './TypingAnimation';
import Completed from './Completed';
import apiService from '../Services/apiService';
import { Referee } from '../Types/referee';
import { isAbandoned, isCancelled, isCompleted } from '../Services/helpers';
import WelcomeMessage from './WelcomeMessage';
import QuestionsModal from './Questions';
import LoadingScreen from './Loading';
import Cancelled from './Cancelled';

const Conversation = () => {
    let { guid } = useParams<{ guid: string }>();
    const [referee, setReferee] = useState<Referee | null>(null);
    const [inputMessage, setInputMessage] = useState("");
    const [isNotFound, setIsNotFound] = useState(false);
    const [isReplying, setIsReplying] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showQuestions, setShowQuestions] = useState(false);

    const inputRef = useRef<HTMLTextAreaElement>(null);
    const bottomRef = useRef<HTMLDivElement>(null);

    const handleAcknowledgement = async () => {
        if (guid) {
            try {
                const updatedReferee = await apiService.acknowledgeWelcomeMessage(guid);
                setReferee(updatedReferee);
            } catch (error) {
                console.error("Error acknowledging welcome message:", error);
            }
        }
    };

    useEffect(() => {
        const fetchConversation = async () => {
            setIsLoading(true);
            try {
                const response = await apiService.getConversation(guid);
                if (response === null) {
                    setIsNotFound(true);
                    setIsLoading(false);
                    return;
                }

                setReferee(response);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching conversation:', error);
            }
        };

        fetchConversation();
    }, [guid]);

    const sendMessage = async () => {
        if (bottomRef.current)
            bottomRef.current.scrollIntoView({ behavior: "smooth" });

        setReferee((prevData) => {
            if (prevData) {
                return {
                    ...prevData,
                    conversation: [...prevData.conversation, { role: 'user', content: inputMessage }],
                }
            }
            return null;
        });
        setInputMessage("");
        setIsReplying(true);
        const newData = await apiService.postMessage(guid, inputMessage);
        setReferee(newData);
        setIsReplying(false);
    };

    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [referee]);

    useEffect(() => {
        if (!isReplying && bottomRef.current) { // Only scroll when isReplying is turned off
            bottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [isReplying]);

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey) { // Check if the shift key is not pressed along with Enter
            e.preventDefault(); // Prevent the default behavior (newline)
            sendMessage();
        }
    };

    if (isLoading) {
        return <LoadingScreen />
    }

    if (isNotFound || !referee) {
        return <p>Reference Check not found...</p>;
    }

    if (isCancelled(referee?.status)) {
        return <Cancelled referee={referee} />;
    }

    if (isAbandoned(referee?.status)) {
        return <p className="mt-10">Thank you, this Reference Check has been declined and you will no longer recieve notifications.</p>;
    }

    if (isCompleted(referee?.status)) {
        return <Completed referee={referee} />;
    }

    if (!referee.welcomeMessageAcknowledged) {
        return <WelcomeMessage referee={referee} onAcknowledge={handleAcknowledgement} />
    }

    return (
        <div className='h-screen flex flex-col'>
            <div className="sticky top-0 z-10 bg-white">
                <Header referee={referee} setShowQuestions={setShowQuestions} showQuestions={showQuestions} />
            </div>
            {showQuestions ?
                <QuestionsModal referee={referee} />
                :
                <>
                    <div className="overflow-auto flex flex-col justify-between pb-3 lg:px-2">
                        {referee.conversation.map((message, index) => (
                            <MessageBubble key={index} role={message.role} content={message.content} />
                        ))}
                        {isReplying && <MessageBubble role="assistant" content={<TypingAnimation />} />}
                        <div ref={bottomRef} />
                    </div>
                    <div className="sticky bottom-0 z-10 bg-white flex items-center pb-2 lg:pb-5">
                        <textarea
                            autoCapitalize='sentences'
                            className="w-full px-5 py-3 border rounded-l-md resize-none overflow-hidden"
                            rows={Math.min(3, inputMessage.split('\n').length)}
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            onKeyDown={handleKeyDown}
                            ref={inputRef}
                            disabled={isReplying}
                        />
                        <button
                            className={`px-5 py-4 font-medium rounded-r-md flex items-center justify-center
                    ${!isReplying ? 'bg-blue-600 text-white hover:bg-blue-500' : 'bg-gray-400 text-gray-50 pointer-events-none'}`}
                            onClick={sendMessage}
                            disabled={isReplying}
                        >
                            <PaperAirplaneIcon className="h-5 w-5" />
                        </button>
                    </div>
                </>
            }
        </div>
    );
};

export default Conversation;
