import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Referee } from '../Types/referee';
import { useNavigate } from 'react-router-dom';

interface IWelcomeMessageProps {
    referee: Referee;
    onAcknowledge: () => void;
}

const WelcomeMessage: React.FC<IWelcomeMessageProps> = ({ referee, onAcknowledge }) => {
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);

    const handleDecline = () => {
        navigate(`/conversation/${referee.id}/abandon`);
    };

    const renderQuestionWithNewLines = (questionText: string, questionNumber: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined) => {
        const paragraphs = questionText.split('\n\n');
        return paragraphs.map((paragraph, paraIndex) => (
            <React.Fragment key={paraIndex}>
                {paraIndex === 0 && (<span>{questionNumber}. </span>)}
                {/* Split the paragraph on single new lines to get the individual lines */}
                {paragraph.split('\n').map((line, lineIndex) => (
                    <React.Fragment key={lineIndex}>
                        {line}
                        {/* Add a line break if it's not the last line in the paragraph */}
                        {lineIndex < paragraph.split('\n').length - 1 && <br />}
                    </React.Fragment>
                ))}
                {/* Add a double break (extra paragraph space) if not the last paragraph */}
                {paraIndex < paragraphs.length - 1 && (<><br /><br /></>)}
            </React.Fragment>
        ));
    };

    return (
        <div className="flex flex-col h-screen max-w-6xl space-y-4 lg:space-y-8 my-6 mx-3 lg:mx-6 md:m-16 lg:mx-auto">
            <div>
                <img className="h-12 lg:h-20" src={referee.orgLogoUrl} alt="Uniting" />
            </div>
            <p className='text-2xl'>Hey <span className="font-bold">{referee.name}</span> 👋</p>
            <h1 className="text-2xl lg:text-3xl text-left font-semibold -ml-2"><span className='font-extrabold bg-blue-50 rounded px-2 py-1'>{referee.candidateName}</span> has nominated you as a referee.</h1>
            <ReactMarkdown className="space-y-4">
                {referee.welcomeMessage}
            </ReactMarkdown>
            <p className='text-2xl'>Here are the {referee.questions.length} questions you will be asked.</p>
            <ul className="space-y-3 font-medium">
                {referee.questions.map((q, index) => (
                    <li key={index} className="bg-blue-50 rounded px-3 py-3">
                        {renderQuestionWithNewLines(q.question, index + 1)}
                    </li>
                ))}
            </ul>
            <div className='flex justify-center'>
                <div className="relative flex items-start">
                    <label htmlFor="agree" className="flex items-start cursor-pointer">
                        <div className="flex h-6 items-center">
                            <input
                                id="agree"
                                aria-describedby="agree-description"
                                name="agree"
                                type="checkbox"
                                className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                onChange={(e) => setIsChecked(e.target.checked)}
                            />
                        </div>
                        <div className="ml-3 leading-6">
                            <span id="agree-description" className="text-gray-500">
                                Do you agree to undertake this reference check for {referee.candidateName}?
                            </span>
                        </div>
                    </label>
                </div>
            </div>
            <div className='flex justify-center'>
                <button
                    type="button"
                    className={`rounded-md px-3.5 py-2.5 text-lg font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600
                    ${isChecked ? 'bg-blue-600 text-white hover:bg-blue-500' : 'bg-gray-400 text-gray-50 pointer-events-none'}`}
                    disabled={!isChecked}
                    onClick={onAcknowledge}
                >
                    Let's begin!
                </button>
            </div>
            <div className='flex justify-center mb-4'>
                <button
                    type="button"
                    className="mb-4 text-sm text-blue-800 hover:bg-red-200 hover:rounded"
                    onClick={handleDecline}
                >
                    Click here if you'd like to decline this reference check
                </button>
            </div>
        </div>
    );
};

export default WelcomeMessage;
