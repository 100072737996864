import React from 'react';

interface IMessageBubbleProps {
    role: string;
    content: React.ReactNode; // content can be a string or JSX
}

const MessageBubble: React.FC<IMessageBubbleProps> = ({ role, content }) => {
    // Function to format content with new lines as separate paragraphs
    const formatContent = (content: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined) => {
        // Check if content is a string and contains new line characters
        if (typeof content === 'string' && content.includes('\n')) {
            // Split the string on double new lines to separate paragraphs
            return content.split('\n\n').map((paragraph, paraIndex) => (
                <React.Fragment key={paraIndex}>
                    {/* Split the paragraph on single new lines to get the individual lines */}
                    {paragraph.split('\n').map((line, lineIndex) => (
                        <React.Fragment key={lineIndex}>
                            {line}
                            {/* Add a line break if it's not the last line in the paragraph */}
                            {lineIndex < paragraph.split('\n').length - 1 && <br />}
                        </React.Fragment>
                    ))}
                    {/* Add a double break (extra paragraph space) if not the last paragraph */}
                    {paraIndex < content.split('\n\n').length - 1 && <><br /><br /></>}
                </React.Fragment>
            ));
        } else {
            // If content does not contain new lines or is JSX, render it as is
            return content;
        }
    };

    return (
        <div className={`my-2 max-w-md font-sans font-medium flex items-start ${role === 'user' ? 'self-end justify-end flex-row-reverse' : 'self-start'}`}>
            {role !== 'user' &&
                <img className="h-6 w-6 mr-2 mt-1 my-1 rounded-full" src="https://cdn.zipline.io/maddie.jpg" alt="Assistant Avatar" />
            }
            <div className={`py-2 px-3 rounded-lg ${role === 'user' ? 'text-slate-800 bg-slate-300' : 'text-slate-700 bg-slate-100'}`}>
                {/* Render formatted content */}
                {formatContent(content)}
            </div>
        </div>
    );
};

export default MessageBubble;
