import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Conversation from './Components/Conversation';
import Abandon from './Components/Abandon';

function App() {
  return (
    <div className="px-3 lg:px-5 max-w-6xl mx-auto">
      <Router>
        <Routes>
          <Route path="/conversation/:guid" element={<Conversation />} />
          <Route path="/conversation/:guid/abandon" element={<Abandon />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
