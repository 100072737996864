// Header.tsx
import React from 'react';
import { Referee } from '../Types/referee';

interface IHeaderProps {
    referee: Referee;
    setShowQuestions: React.Dispatch<React.SetStateAction<boolean>>;
    showQuestions: boolean;
}

const Header: React.FC<IHeaderProps> = ({ referee, setShowQuestions, showQuestions }) => {
    return (
        <div className="md:border-b md:border-slate-100 py-2 md:py-4 mb-2 md:mb-5 mx-2 md:mx-0 space-y-2">
            <div className="flex py-2 justify-between items-center">
                <img className="h-8 -mt-2" src={referee.orgLogoUrl} alt="Wallaby" />
                <button
                    type="button"
                    className="rounded bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600 shadow-sm hover:bg-blue-100"
                    onClick={() => setShowQuestions(prev => !prev)}
                >
                    {showQuestions ? "Back to conversation" : "Get help"}
                </button>
            </div>
            <h2 className="text-center text-sm md:text-lg font-semibold text-slate-800">Reference Check for {referee.candidateName}</h2>
            <div className="transition ease-in-out w-full bg-gray-200 rounded-full">{
                referee.percentCompleted > 0 ?
                    <div className="transition ease-in-out bg-blue-400 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: `${referee.percentCompleted}%` }}> {referee.percentCompleted}%</div>
                    :
                    <div className="transition ease-in-out bg-gray-200 text-xs font-medium rounded-full text-center text-gray-400 leading-none p-0.5"> {referee.percentCompleted}%</div>
            }
            </div>
        </div>
    );
};

export default Header;
